@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./fonts/ProximaNova-Regular.woff2) format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./fonts/ProximaNova-Semibold.woff2) format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./fonts/ProximaNova-Bold.woff2) format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./fonts/ProximaNova-Black.woff2) format('woff2');
  font-weight: 900;
}

.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-body {
  display: flex;
  flex: 1 1;
  background: #FFFFFF;
}

.App-body-form {
  width: 448px;
  height: 504px;
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 72px #EEF3FD;
  border-radius: 18px;
  padding: 48px;
}

.App-body-top {
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: flex-end;
}

.App-title {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;

  /* identical to box height */
  text-align: center;
  letter-spacing: -1px;

  color: #121223;
}

.App-description {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */
  text-align: center;
  letter-spacing: -0.2px;

  color: #A2A2AD;
}

.App-address-container {
  display: flex;
  height: 64px;
  background: #FAFBFF;
  border-radius: 14px;
  margin-top: 50px;
  position: relative;
}

.App-address-container input {
  flex: 1;
  padding: 22px;
  background: transparent;
  border: none;
  outline: none;
  background: #FAFBFF;
  border-radius: 14px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;

  color: #A2A2AD;
}

.App-address-container input::placeholder {
  color: #A2A2AD;
}

.App-address-container input.error {
  border: 1px solid #FF5151;
}

.App-body-bottom {
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: flex-start;
}

.App-body-bottom a {
  margin-bottom: 15px;
}

.App-request-btn {
  background: #1969FF;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  padding: 15px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.2px;

  color: #FFFFFF;
  border: none;
  outline: none;
  width: 100%;
}

.App-request-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.App-bottom-status {
  margin-top: 50px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  /* or 24px */
  text-align: center;
  letter-spacing: -0.2px;

  color: #A2A2AD;
}

.App-bottom-info {
  background: #F7F9FF;
  border-radius: 14px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #1969FF;
  padding: 15px 32px 20px;
  margin-top: 40px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Red {
  color: red;
}

.App-bottom-status a {
  color: #1969FF;
  font-weight: bold;
  text-decoration: none;
}

.App-logo {
  position: absolute;
  top: 30px;
  left: 45px;
  width: 150px;
  pointer-events: none;
}

.App-footer {
  height: 50px;
  background: #414751;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-footer span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.info-icon {
  margin-right: 15px;
  margin-top: 2px;
  width: 15px;
  height: 15px;
}

.App-bottom-error {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  letter-spacing: -0.2px;

  color: #FF5151;
  text-align: left;
  padding-left: 24px;
  margin: 5px 0;
}

.Address-error-btn {
  position: absolute;
  right: 20px;
  border: none;
  background: none;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
}

.App-address-container>input.error~.Address-error-btn {
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
}

.App-notification {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  text-align: center;
  letter-spacing: -0.2px;

  margin-top: 10px;
}

.App-notification.Red {
  color: #FF5151;
}

.App-notification a {
  text-decoration: none;
  color: #1969FF;
}

@media screen and (max-width: 767px) {
  .App-logo {
    left: 16px;
    top: 40px;
    width: 100%;
    max-width: 111px;
  }
  .App-body {
    padding: 16px;
  }
  .App-body-form {
    width: calc((100% - 15px));
    padding: 15px;
    margin-top: 120px;
    height: unset;
  }
  .App-address-container input {
    width: 100%;
  }
  .App-title {
    margin-top: 17px;
    overflow-wrap: break-word;
  }
  .App-address-container {
    margin-top: 24px;
  }
  .App-notification {
    margin-top: 18px;
  }
  .App-bottom-status {
    margin-top: 70px;
    overflow-wrap: break-word;
  }
  .App-bottom-info {
    margin: 10px;
    margin-top: 24px;
  }
  .App-request-btn {
    max-width: 448px;
  }
}

@media screen and (max-width: 240px) {
  .App-bottom-info {
    padding: 15px;
    flex-wrap: wrap;
  }
}